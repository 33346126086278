import React, { useState, useEffect } from 'react'
//import Sidebar from './component/Sidebar';
import Login from "./pages/Login";
import Login1 from "./pages/Login1";
import Report from "./pages/Report";
import Report1 from "./pages/Report1";
import CompanyProfile from "./pages/CompanyProfile";
import ComplianceReports from "./pages/ComplianceReports";
import Test from "./pages/Test";
import Test1 from "./pages/Test1";
import TaskManager from "./pages/TaskManager";
import UpdateRequests from "./pages/UpdateRequests";
import EmptyISINS from "./pages/EmptyISINS";
import MarketsSettings from "./pages/MarketsSettings";
import EmptyMainTickers from "./pages/EmptyMainTickers";
import MissingMainTickers from "./pages/MissingMainTickers";
import ETFS from "./pages/ETFS";
import ETFHoldingsBasket from "./pages/ETFHoldingsBasket";
import StockReport from "./pages/StockReport";
import CompanyProfileReports from "./pages/CompanyProfileReports";
import ComplianceProfileReports from "./pages/ComplianceProfileReports";
import AddNewCompanyProfile from "./pages/AddNewCompanyProfile";
import ETFHolding from "./pages/ETFHolding";
//import DragDrop from "./pages/DragDrop";
import TableAccordion from "./pages/TableAccordion";
import ComplianceView from "./pages/ComplianceView";
import ComplianceMerlineView from "./pages/ComplianceMerlineView";
import ChangePassword from "./pages/ChangePassword";
import CoverageRequests from "./pages/CoverageRequests";
import ETFS1 from "./pages/ETFS1";

// import PDFHighlighter from "./pages/PDFHighlighter";
// import PDFHighlight from "./pages/PDFHighlight";
import Timer from "./pages/Timer";
import Timer2 from "./pages/Timer2";

import IndustrySector from "./pages/IndustrySector";
import Ranking from "./pages/Ranking";
import UsersandRoles from "./pages/UsersandRoles";
import TooltipTest from "./pages/TooltipTest";
import Dashboard from "./pages/Dashboard";
import UncoveredStock from "./pages/UncoveredStock";
import DataTable from "./pages/DataTable";
import History from "./pages/History";
import MissingCompanies from "./pages/MissingCompanies";
import DeletedReports from "./pages/DeletedReports";
import PageDemo from "./pages/PageDemo";
import ManualCompanies from "./pages/ManualCompanies";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 

import "./css/style.css";
import LoadingBar from 'react-top-loading-bar';

//import $ from "jquery";


const App = () => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    //console.log(window.location.pathname);

    useEffect(() => {
        simulateDataLoad();
      }, [])

      const simulateDataLoad = () => {
        setProgress(30);
    
        // Simulating data loading
        setTimeout(() => {
          setProgress(70);
    
          setTimeout(() => {
            setProgress(100);
            setIsLoading(false);
          }, 1000);
        }, 2000);
      };

      // Add at the top of your file
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

    return(
        <>
         <div> <LoadingBar color="#FCB633" progress={isLoading ? progress : 100} onLoaderFinished={() => setProgress(0)} /> </div>
        <Router basename='/'> 
         {/* {window.location.pathname !== '/nova' && <Sidebar />}
        <Sidebar/> */}
            <Routes> 
                <Route path="/" element={<Login />} />
                <Route path="/Login" element={<Login1 />} />
                {/* <Route path="/report" element={<Report />} /> */}
                {/* <Route path="/report/:param1/:param2/:param3" element={<Report />} /> */}
                <Route path="/report/:encryptJSON" element={<Report />} />

                <Route path="/compliance-reports" element={<ComplianceReports />} />
                <Route path="/company-profile" element={<CompanyProfile />} />
                <Route path="/TaskManager" element={<TaskManager />} />
                 <Route path="/report1" element={<Report1 />} />
                 <Route path="/Test" element={<Test />} />
                 <Route path="/Test1" element={<Test1 />} />
                <Route path="/UpdateRequests" element={<UpdateRequests />} />
                <Route path="/Empty-ISINS" element={<EmptyISINS />} />
                <Route path="/MarketsSettings" element={<MarketsSettings />} />
                <Route path="/EmptyMainTickers" element={<EmptyMainTickers />} />
                <Route path="/MissingMainTickers" element={<MissingMainTickers />} />
                <Route path="/ETFS" element={<ETFS />} />                
                <Route path="/ETFHoldingsBasket" element={<ETFHoldingsBasket />} />   
                <Route path="/StockReport" element={<StockReport />} /> 
                <Route path="/CompanyProfileReports" element={<CompanyProfileReports />} /> 
                <Route path="/ComplianceProfileReports/:company_symbol" element={<ComplianceProfileReports />} /> 
                <Route path="/AddNewCompanyProfile" element={<AddNewCompanyProfile />} /> 
                <Route path="/ETFHolding/:company_symbol" element={<ETFHolding />} />    
                {/* <Route path="/DragDrop" element={<DragDrop />} />   */}
                <Route path="/TableAccordion" element={<TableAccordion />} />  
                {/* <Route path="/PDFHighlighter" element={<PDFHighlighter />} />   
                <Route path="/PDFHighlight" element={<PDFHighlight />} />   */}
                <Route path="/Timer" element={<Timer />} />  
                <Route path="/Timer2" element={<Timer2 />} />

                <Route path="/Ranking" element={<Ranking />} />
                <Route path="/UsersandRoles" element={<UsersandRoles />} />
                <Route path="/IndustrySector" element={<IndustrySector />} />
                <Route path="/ComplianceView/:encryptJSON" element={<ComplianceView />} />
                <Route path="/ComplianceMerlineView/:encryptJSON" element={<ComplianceMerlineView />} />
                <Route path="/ChangePassword" element={<ChangePassword />} />
                <Route path="/TooltipTest" element={<TooltipTest />} />
                <Route path="/CoverageRequests" element={<CoverageRequests />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/UncoveredStock" element={<UncoveredStock />} />
                <Route path="/DataTable" element={<DataTable />} />
                <Route path="/ETFS1" element={<ETFS1 />} />
                <Route path="/History" element={<History />} />  
                <Route path="/MissingCompanies" element={<MissingCompanies />} />
                <Route path="/DeletedReports" element={<DeletedReports />} />    
                <Route path="/PageDemo" element={<PageDemo />} />    
                <Route path="/ManualCompanies" element={<ManualCompanies />} />    
                
            </Routes>        
        </Router>     
        </>     
    );
};

export default App;

