import React, { useState, useEffect, useCallback, useRef } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Dropdown, Placeholder, Whisper, Button, Popover, Message, useToaster, Tooltip } from "rsuite";
import 'rsuite/SelectPicker/styles/index.css';
import IconExcel from "../images/icon-excel.svg";
import IconEFTToggle from "../images/btn_eft_toggle.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useParams ,Link, useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";

import icon_Asc from "../images/Asc.svg";
import icon_Desc from "../images/Desc.svg";

import icon_Asset_Asc from "../images/Asc.svg";
import icon_Asset_Desc from "../images/Desc.svg";

import icon_Share_Asc from "../images/Asc.svg";
import icon_Share_Desc from "../images/Desc.svg";

import icon_TotalRev_Asc from "../images/Asc.svg";
import icon_TotalRev_Desc from "../images/Desc.svg";
import imgBack from "../images/icon-back.svg";



function ETFHolding() {
    const [tabName, setTabName] = useState('ETF Holding');
    const { company_symbol } = useParams();



    const navigate = useNavigate();

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

const data = ['India', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
);
const data2 = ['Cash', 'Bond', 'Derivative', 'Stock', 'ETF', 'Cash (Shariah-Compliant)', 'Money Market Fund', 'Proffered Stock (Not Compliant) ', 'Other Items', 'Other'].map(
    item => ({ label: item, value: item })
);

const cbaStatus = ['All','Pass', 'Fail'].map(
  item => ({ label: item, value: item })
);


// TOGGLE SHOW HIDE
const [isContentVisible, setIsContentVisible] = useState(false);

const toggleContent = () => {
    setIsContentVisible(prevState => !prevState);
};

useEffect(() => {
    AOS.init();
  }, []);

  const abortControllerRef = useRef(null);
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;
  const apiEndPoint1 = process.env.REACT_APP_API_ENDPOINT;
  const [pageRange, setPageRange] = useState("100");

  const [reportData, setReportData] = useState([]);
  const [etfData, setETFData] = useState("");
  const [etfName, setEtfName] = useState("");
  const [weight, setWeight] = useState("");
  const [calculationType, setCalculationType] = useState("");

  const [totalRevenue, setTotalRevenue] = useState("");
  const [totalRevenueCurrency, setTotalRevenueCurrency] = useState("");
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [cbastatusId, setcbastatusId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [assetClassData, setAssetClassData] = useState([]);
  const [searchAsset, setSearchAsset] = useState("");
  const [manualStatus, setmanualStatus] = useState(false);

  
  const [isAscending, setIsAscending] = useState(true);
  const [isAssetAscending, setIsAssetAscending] = useState(true);
  const [isShareAscending, setIsShareAscending] = useState(true);
  const [isTotalRevAscending, setIsTotalRevAscending] = useState(true);
  
const [orderBy, setOrderBy] = useState("assetType"); // default orderBy
const [order, setOrder] = useState("ASC"); // default order

  const handleIconClick = (columnName) => {
    // Toggle between ascending and descending icons
    
    setOrderBy(columnName);

    switch (columnName) {
      case "assetType":
        setIsAssetAscending(!isAssetAscending);
        setOrder(isAssetAscending ? "DESC" : "ASC");
        break;
      case "percent":
        setIsAscending(!isAscending);
        setOrder(isAscending ? "DESC" : "ASC");
        break;
        case "shares_outstanding":
          setIsShareAscending(!isShareAscending);
          setOrder(isShareAscending ? "DESC" : "ASC");
          break;
          case "total_revenue":
            setIsTotalRevAscending(!isTotalRevAscending);
            setOrder(isTotalRevAscending ? "DESC" : "ASC");
            break;
      // Add more cases for other columns if needed
      default:
        setIsAssetAscending(!isAssetAscending);
        setOrder(isAssetAscending ? "DESC" : "ASC");
        break;
    }
    
  };
  

  
    const fetchGlobalReport = async () => {
      setLoading(true);
  // Cancel the previous request if any
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

   // Create a new AbortController for the current request
   const controller = new AbortController();
   abortControllerRef.current = controller;


    try {
        const apiUrl = `${apiEndPoint}/etf_company_holding`;
        const requestData = {
          data: {
            user_id: userid,
            limit: pageRange,
            page: currentPage.toString(),
            q:searchInput,
            asset_class:searchAsset,
            etf_symbol:company_symbol,
            status: complianceId,
            country: countryCode || "",
            order: order,
            orderBy:orderBy,
            cba: cbastatusId === "All" ? "" : cbastatusId
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData),
          signal: controller.signal
        });
  
        const jsonData = await response.json();
        const data = jsonData.data; 
        //const { status, message, count, data, ETF_Data,total_Revenue,total_Revenue_currency,Etf_Name,Calculation_Type } = jsonData.data;
        if (data.status == "success") {
           setLoading(false);
           setReportCount(data.count);
           console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data.data);
          setETFData(data.ETF_Data);
          setEtfName(data.Etf_Name);
          setWeight(data.sum);
            setCalculationType(data.Calculation_Type);
          console.log(JSON.stringify(data.ETF_Data));
          setTotalRevenue(data.total_Revenue);
          setTotalRevenueCurrency(data.total_Revenue_currency);

          // console.log('total_revenue_sum : '+ data.sum.total_revenue_sum);
          // console.log('compliant_revenue_sum : '+ data.sum.compliant_revenue_sum);
          // console.log('non_compliant_revenue_sum : '+ data.sum.non_compliant_revenue_sum);
          // console.log('doubtful_revenue_sum : '+ data.sum.doubtful_revenue_sum);
          // console.log('interest_bearing_assets_sum : '+ data.sum.interest_bearing_assets_sum);
          // console.log('interest_bearing_liabilities_sum : '+ data.sum.interest_bearing_liabilities_sum);
          // console.log('AUM_sum : '+ data.sum.AUM_sum);

          console.log('etf_total_revenue_sum : '+ data.sum.etf_total_revenue_sum);
          console.log('etf_compliant_revenue_sum : '+ data.sum.etf_compliant_revenue_sum);
          console.log('etf_non_compliant_revenue_sum : '+ data.sum.etf_non_compliant_revenue_sum);
          console.log('etf_doubtful_revenue_sum : '+ data.sum.etf_doubtful_revenue_sum);
          console.log('etf_interest_bearing_assets_sum : '+ data.sum.etf_interest_bearing_assets_sum);
          console.log('etf_interest_bearing_liabilities_sum : '+ data.sum.etf_interest_bearing_liabilities_sum);
          console.log('etf_AUM_sum : '+ data.sum.etf_AUM_sum);
          console.log('total_weight : '+ data.sum.total_weight);
          console.log('compliant_weight : '+ data.sum.compliant_weight);
          console.log('non_compliant_weight : '+ data.sum.non_compliant_weight);
          console.log('doubtful_weight : '+ data.sum.doubtful_weight);
          console.log('uncover_weight : '+ data.sum.uncover_weight);

        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request cancelled");
        }
        else{
        console.error("Error fetching data:", error.message);
        }
        return false;
      }
    };
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId,searchAsset,manualStatus,orderBy,order,cbastatusId, pageRange]); // Regular effect for other dependencies

    
      const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };


    const [customPaginationValue, setCustomPaginationValue] = useState("");

    // Debounced function to update the page number
    const debouncedSetPage = useCallback(
      debounce((page) => {
        setCurrentPage(page);
      }, 500), // Delay of 500ms (half a second)
      []
    );
  
    const handlePaginationChange = (e) => {
      const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
      setCustomPaginationValue(value); // Update input value immediately for fast typing
      debouncedSetPage(Number(value)); // Trigger the debounced function
    };
  
    const handlePagelimit = (value) => {
      setCurrentPage(1);
      setCustomPaginationValue(1);
      setPageRange(value); 
    };
  
    
    const renderPagination = () => {
      // Calculate total number of pages
      const totalPages = Math.ceil(reportCount / pageRange);
    
      // Show a default of 3 pages if the total pages are more than 3
      const pageNumbers = [];
      const visiblePageCount = 4; // Number of pages to show by default
    
      let startPage = currentPage - 1;
      let endPage = currentPage + 2;
    
      // Adjust pagination if total pages are less than the default
      if (totalPages <= visiblePageCount) {
        startPage = 1;
        endPage = totalPages;
        
      } else {
        
        // Ensure the pagination does not go out of bounds
        startPage = Math.max(1, startPage);
        endPage = Math.min(totalPages, endPage);
      }
    
      // Create page numbers to display based on current page
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    
      return (
        <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
          <div className='totalRecords'>
            Total Records :  {Number(reportCount).toLocaleString()}
          </div>
          <ul className="pagination" style={{border: 0, background: 'none'}}>
  
          <li class="page-item" style={{marginLeft:10}}>
                        <SelectPicker onChange={handlePagelimit} cleanable={false}
                        value={pageRange} 
                        placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                      </li>
  
            {/* Previous Page (Double Arrow) */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
                <i className="fa fa-angle-double-left"></i>
              </a>
            </li>
    
            {/* Previous Page (Single Arrow) */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
                <i className="fa fa-angle-left"></i>
              </a>
            </li>
    
            {/* Page numbers */}
            {pageNumbers.map(page => (
              <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <a 
                  className="page-link" href="#" 
                  onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
                >
                  {page}
                </a>
              </li>
            ))}
            
            {/* Next Page (Single Arrow) */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
                <i className="fa fa-angle-right"></i>
              </a>
            </li>
    
            {/* Next Page (Double Arrow) */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
                <i className="fa fa-angle-double-right"></i>
              </a>
            </li>
            
            {/* Go to Page */}
            <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
              <input
                type="text"
                placeholder="Go#"
                className="form-control"
                value={customPaginationValue}
                onChange={handlePaginationChange}
                onInput={(e) => {
                  // Only allow numbers
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              
                style={{
                  padding: '0px 10px', 
                  color: '#878787', 
                  fontSize: 16, 
                  borderRadius: 5, 
                  border: '1px solid #A1B9ED', 
                  width: 60, 
                  height: 35, 
                  textAlign: 'center'
                }}
              />
            </li>
          </ul>
        </nav>
      );
    };
    
    
    
  
  
  // PAGINATION
  const PagingData = ['10', '20', '50', '100'].map(
    item => ({ label: item, value: item })
  );

    const handleClearFilters = () => {
      setCurrentPage(1);
      setSearchInput("");
      setSearchAsset("");
      setComplianceId("");
      setCountryCode("");
      setcbastatusId("");
    };

    
    const handleAssetClass = (value) => {
        setSearchAsset(value);
        setCurrentPage(1); // Reset to the first page for new search
      };


    useEffect(() => {
        // Fetch the data from API
        const fetchAssetClasses = async () => {
          try {
            const response = await fetch(`${apiEndPoint}/getAssetClassHoldings`);
            const result = await response.json();
    
            if (result.data.status === 'success') {
              // Transform the data to fit SelectPicker's format
              const transformedData = result.data.data.map((item) => ({
                label: item.AssetClass,
                value: item.AssetClass,
              }));
              
              setAssetClassData(transformedData);
            }
          } catch (error) {
            console.error('Error fetching asset classes:', error);
          }
        };
    
        fetchAssetClasses();
      }, []);

      


      // Function to handle the asset class change
      const handleAssetClassChange = (id, value) => {
        // Find the relevant item in the reportData to extract the necessary data for the API call
        const selectedItem = reportData.find((item) => item.ID== id);
    

        // Update the state with the new AssetClass value
        setReportData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, AssetClass: value } : item
          )
        );
    
        // Prepare the data for the API request
        const apiData = {
            data: {
                user_id: userid, // You can replace this with the actual user ID if dynamic
                etf_symbol:company_symbol , // Replace with the correct property from your data
                symbols: [selectedItem.Symbol],         // Replace with the correct property from your data
                asset_class: value                   // The updated asset class
            }
        };

        console.log(JSON.stringify(apiData));

        // Make the API call to update the asset class
        fetch(`${apiEndPoint}/update_assetclass_all`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiData),
        })
        .then(response => response.json())
        .then(data => {
          // alert(JSON.stringify(data));
            console.log('Success:', data);
            fetchGlobalReport();
            // You can handle success, maybe update some UI or state based on the response
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle errors here
        });
    };
    


  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleRowClick = (id) => {
    console.log(expandedRowId + ' : '+id );
    if(expandedRowId == id)
    {
      setExpandedRowId(null);
    }
    else{
      // Toggle the expanded row
    setExpandedRowId(id);
    //setExpandedRowId(prevId => (prevId === id ? null : id));
      
    }
    
  };

  const [openDeletePopover, setOpenDeletePopover] = useState(null);
  const [calculateLoading, setCalculateLoading] = useState(false);

  const handleCalculateConfirm = () => {
    setCalculateLoading(true);
  
    const url = `${apiEndPoint}/calculate_single_etf`;
    const payload = {
      data: {
        user_id: userid,
        etf_symbol: company_symbol,
      },
    };
    console.log(JSON.stringify(payload));
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())  // Parse the JSON response
      .then((data) => {
        if (data?.data?.status === "success") {
          setmanualStatus(true);
          setErrorMessage(data?.data?.message);
          setMessageType('success');
          console.log('Manual Calculation completed successfully:', data.data);
          setOpenDeletePopover(null);

        } else {
          console.error('Calculation failed:', data);
          setErrorMessage(data?.data?.message);
          setMessageType('error');
          setOpenDeletePopover(null);
        }
        setCalculateLoading(false);  // Stop loading after success
      })
      .catch((error) => {
        console.error('Manual Calculation Error:', error);
        setErrorMessage(error);
        setMessageType('error');
        setCalculateLoading(false);  // Stop loading in case of error
        setOpenDeletePopover(null);
      });
  };
  


const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleCbaChange = (value) => {
  setcbastatusId(value);
};



  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint1}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);


  const handleCountryChange = (value) => {
    setCurrentPage(1);
    setCountryCode(value);
  };


  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list_etf`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        setErrorMessage(error.message);
        setMessageType('error');
        return false;
      }
    };
  
    fetchCountryList();
  }, []);


  const hideMessage = () => {
    setMessageType(null);
  };
  
  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 4000);
    };
  
    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 99999}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>No reports found for the selected priority</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };

  let AUMSUM = 0;

  const handleBack = async () => {
    navigate("/ETFS");
  };


  return (
    <>
    <Sidebar/>
    <div id='header'>
    {renderMessage()}

        <div className='logo'>
          <Button className='btn_Back_head' onClick={handleBack}><img src={imgBack}/></Button>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>{etfName ? `${etfName}` : ''}</div>
        </div>

        <div className='header_table_filter' style={{width:"auto"}}>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search2'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search' onChange={handleSearch}   value={searchInput} />
            </div>

            <div className='dropdown_style filter_by_country'>
            <SelectPicker
                    data={assetClassData}
                    searchable={false}
                    style={{ width:180 }}
                    placeholder="All Asset classes"
                    onChange={handleAssetClass} 
                    value={searchAsset} // Bind searchAsset to the value
                    />
            </div>
            <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

            <div className='dropdown_style filter_by_country'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <div className='dropdown_style filter_by_country'>
            <SelectPicker
              data={cbaStatus}
              searchable={false}
              style={{ width: 120 }}
              placeholder="CBA Status"
              onChange={handleCbaChange}
              value={cbastatusId}
            />
          </div>

          

            {/* <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{width:'100%' }}
                    placeholder="All Compliance statuses"
                    />
            </div> */}
            <a href='javascript:void(0)' className="btn_clear_filter"  style={{textDecoration:"none"}} onClick={handleClearFilters}>Clear</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
     <div id="etf_toggle_row" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
        <div className='etf_head_row'>

       {etfData.length > 0 ? (
        <>
            <ul>
            {etfData[0].Etf_Symbol ? <li className='stock_lable'>{etfData[0].Etf_Symbol}</li> : ''}
            <Whisper
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      speaker={<Tooltip>{etfData[0].Etf_msg}</Tooltip>}
    >
            <li className={`tag_td ${ etfData[0].Etf_Complience == "" || etfData[0].Etf_Complience == null ? '' : etfData[0].Etf_Complience == "CMPT" ? 'halal_tag' : etfData[0].Etf_Complience == "NCMPT" ? 'not_halal_tag' : etfData[0].Etf_Complience == "DBTF" ? 'doughtful_tag' : etfData[0].Etf_Complience == "UCVD" ? 'uncover_tag':'' }`} style={{margin:'auto'}}>{etfData[0].Etf_Complience}</li>
            </Whisper>
            </ul>
            
            <div className='etf_right_controls'>
                <div className='input__group'>
                    <div className='input__group_label'>
                        Total Revenue
                    </div>
                    <div className='input__group_value'>
                      {totalRevenue} {totalRevenueCurrency ? `(${totalRevenueCurrency})` : ''}
                    </div>
                </div>

                <div className='input__group'>
                    <div className='input__group_label'>
                      Calculation Type
                    </div>
                    <div className='input__group_value'>
                       <span className='css_radio'></span><span>{calculationType ? `${calculationType}` : ''}</span>
                    </div>
                </div>
                {/* <button className='btn_export_excel'><img src={IconExcel} />Export to Excel</button> */}
                <Whisper
                placement="left"
                trigger="click"
                open={openDeletePopover}
                onClose={() => setOpenDeletePopover(null)}
                speaker={
                    <Popover className="value_tooltip_form">
                        <div className="tooltip_form_inner text-center">
                            <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                Are you sure you want to <br />
                                Calculate ETF Manually?
                            </div>

                            <div className='btn__group mt-5'>
                                <Button href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</Button>
                                <Button href="#" className='btn__assign' onClick={() => handleCalculateConfirm()} loading={calculateLoading}>Confirm</Button>
                            </div>
                        </div>
                    </Popover>
                }
            >
                <Button className="btn_assign" onClick={() => setOpenDeletePopover(true)}>
                    <div>
                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You can do manual ETF<br/>calculation from here</Tooltip>} >
                        <button className='btn_etf_calculate'>Calculate</button>
                        </Whisper>
                    </div>
                </Button>
            </Whisper>

                
            </div>
            </>
   ) : null}
        </div>
        <div className={`etf_content_row_outer ${isContentVisible ? 'open' : ''}`}>
            <div className='etf_content_row'>
            {etfData[0]?.Market_Info ? (
                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Market Info</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Asset
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Market_Info?.Asset || 'N/A'}
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Market
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Market_Info?.Market}
                        </div>
                    </div>
                </fieldset>
) : (null)}

{etfData[0]?.Holdings ? (
                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Holdings</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Covered
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Holdings.Covered}
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Uncovered
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Holdings.UnCovered}
                        </div>
                    </div>
                </fieldset>
) : (null)}

{etfData[0]?.Compliance ? (

                <fieldset className='fieldset_col three_rows_fieldset'>
                    <legend className='legend_title'>Compliance (USD)</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Halal
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Compliance.Halal_Percent} <span>({etfData[0]?.Compliance.Halal_value})</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Not Halal
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Compliance.Not_Halal_Percent}<span>({etfData[0]?.Compliance.Not_Halal_value} )</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Doubtful
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Compliance.Doubtful_Percent} <span>({etfData[0]?.Compliance.Doubtful_value} )</span>
                        </div>
                    </div>
                </fieldset>
) : (null)}

{etfData[0]?.Interest_Bearing ? (

                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Interest Bearing</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        SecAndAssets
                        </div>
                        <div className='input__group_redis_value' >
                        {etfData[0]?.Interest_Bearing.SecAndAssets_Percent} <span>({etfData[0]?.Interest_Bearing.SecAndAssets_Value})</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Debt
                        </div>
                        <div className='input__group_redis_value'>
                        {etfData[0]?.Interest_Bearing.Debt_Percent} <span>( {etfData[0]?.Interest_Bearing.Debt_Value})</span>
                        </div>
                    </div>
                </fieldset>
) : (null)}

{weight ? (
<fieldset className='fieldset_col three_rows_fieldset'>
                    <legend className='legend_title'>Total Weight(%) ({weight.total_weight})</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        CMPT Weight(%)
                        </div>
                        <div className='input__group_redis_value'>
                        {weight.compliant_weight}
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        NCMPT Weight(%)
                        </div>
                        <div className='input__group_redis_value'>
                        {weight.non_compliant_weight}
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        DBFT Weight(%)
                        </div>
                        <div className='input__group_redis_value'>
                        {weight.doubtful_weight}
                        </div>
                    </div>
                    
                </fieldset>
) : (null)}
            </div>
        </div>

        <button className="btn_eft_toggle" onClick={toggleContent}>
            <img src={IconEFTToggle} />
        </button>
     </div>
    
      <div className='company_table_list etf_holding_table' data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">

      {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
    <>
       
        <div className='table-responsive clsPagingWrap'>
          <table className='table'>
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Company Name</th>
                  <th>Quarter</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th >Compliance</th>
                  <th >CBA</th>
                  <th >Intr</th>
                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("assetType")}>Asset <img   src={isAssetAscending ? icon_Asset_Asc : icon_Asset_Desc} style={{ height: 16, marginLeft: 10 }} alt={isAssetAscending ? "Ascending Icon" : "Descending Icon"}></img></th>
                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("percent")}>Weight(%)<img   src={isAscending ? icon_Asc : icon_Desc} style={{ height: 16, marginLeft: 10 }} alt={isAscending ? "Ascending Icon" : "Descending Icon"}></img></th>
                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("shares_outstanding")}>Shares outstanding <img   src={isShareAscending ? icon_Share_Asc : icon_Share_Desc} style={{ height: 16, marginLeft: 10 }} alt={isShareAscending ? "Ascending Icon" : "Descending Icon"}></img></th>
                  <th >Shares By ETF</th>
                  <th >Value($)</th>
                  
                  <th >LUD</th>
                  <th >CMPT ratio</th>
                  <th >NCMPT ratio</th>
                  <th >DBFT ratio</th>
                  <th >IB assets</th>
                  <th >IB liabilities</th>
                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("total_revenue")}>Total Revenue <img   src={isTotalRevAscending ? icon_TotalRev_Asc : icon_TotalRev_Desc} style={{ height: 16, marginLeft: 10 }} alt={isTotalRevAscending ? "Ascending Icon" : "Descending Icon"}></img></th>
                  <th >ETF Total Revenue</th>
                  <th >CMPT Revenue</th>
                  <th >ETF CMPT Revenue</th>
                  <th >NCMPT Revenue</th>
                  <th >ETF NCMPT Revenue</th>
                  <th >DBFT Revenue</th>
                  <th >ETF DBFT Revenue</th>
                  <th >Share Price</th>
                  <th >ETF AUM</th>
                  <th >IB asset amount</th>
                  <th >ETF IB assets</th>
                  <th >IB liabilities Amt</th>
                  <th >ETF IB liabilities</th>
                </tr>
              </thead>
              <tbody>

              {reportData.length > 0 ? (
  reportData.map((item, index) => (
    <>
     {/* <Whisper
      placement="bottom"
      // controlId="control-id-hover"
      controlId={`control-id-hover-${item.ID}`}
      trigger="hover"
      speaker={<Tooltip>{item.Status_msg || "No status message"}</Tooltip>}
    > */}
      <tr style={{cursor:'pointer'}} className={(item.ShareOutstanding === null ? 'highlightRow ' : '') + (index % 2 === 0 ? 'table_tr_odd' : 'table_tr_even')}
      > 
        <td onClick={() => handleRowClick(item.ID)} >
                      <div className='company_name_td'>
                        <span className='company_lable' style={{width:'auto', paddingLeft:10, paddingRight:10}}>{item.Symbol}</span>
                      </div>
                    </td>

        <td onClick={() => handleRowClick(item.ID)}>
          {item.Company}
        </td>
        
        <td onClick={() => handleRowClick(item.ID)}>
          {item.report_year}
        </td>
        
        <td style={{textAlign:'center', color:'#2659C9'}} onClick={() => handleRowClick(item.ID)}>
          {item.ISIN}
        </td>
        <td style={{textAlign:'center'}} onClick={() => handleRowClick(item.ID)} >
          <span className={`tag_td ${ item.Compliance == "" || item.Compliance == null ? '' : item.Compliance == "CMPT" ? 'halal_tag' : item.Compliance == "NCMPT" ? 'not_halal_tag' : item.Compliance == "DBTF" ? 'doughtful_tag' : 'uncover_tag' }`} style={{margin:'auto'}}>{item.Compliance}</span>
        </td> 

        <td style={{textAlign:'center'}} onClick={() => handleRowClick(item.ID)}>
        <span className={item.CBA && item.CBA.toLowerCase() === 'pass' ? 'cba_pass' : 'cba_Fail'}>{item.CBA}</span>
        </td>

        <td style={{textAlign:'center'}} onClick={() => handleRowClick(item.ID)}>
          {item.Intr}
        </td>
        <td style={{textAlign:'center'}}>
          <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
            <SelectPicker
              data={assetClassData}
              searchable={false}
              placeholder=""  
              value={item.Asset}  
              style={{width:160}}    
              onChange={(value) => handleAssetClassChange(item.ID, value)}    
            />
          </div>
        </td>
        <td style={{textAlign:'center', fontWeight:600}} onClick={() => handleRowClick(item.ID)}>
          {item.Weight}
        </td>                  
       

        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.ShareOutstanding}</div>
        </td>

        <td onClick={() => handleRowClick(item.ID)}>
          <div>{item.Share}</div>
        </td>

        <td onClick={() => handleRowClick(item.ID)}>{item.Value}</td>
                        
       
        <td style={{textAlign:'center'}} onClick={() => handleRowClick(item.ID)}>
          <p style={{color:'#000',}}>{item.LUD}<br />
            <span style={{color:'#878787',}}>-</span></p>
        </td>


        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.Compliant_ratio}</div>
        </td>
      
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.NonCompliant_ratio}</div>
        </td>
      
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.Doubtful_ratio}</div>
        </td>

        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.InterestBearing_Assets}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.Interest_Bearing_Liability}</div>
        </td>
      
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.TotalRevenue}</div>
        </td>

          
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_total_revenue}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.compliant_revenue}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_compliant_revenue}</div>
        </td>
        <td  onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.NonCompliant_Revenue}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_noncompliant_revenue}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.Doubtful_Revenue}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_doubtful_revenue}</div>
        </td>

        <td onClick={() => handleRowClick(item.ID)} className='highlightedRed'>
          <div>{item.SharePrice}</div>
        </td>
      
       
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_AUM}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div >{item.interest_bearing_assets_amount}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div >{item.etf_interest_bearing_asset}</div>
        </td>
        <td onClick={() => handleRowClick(item.ID)} className='highlightedGreen'>
          <div>{item.interest_bearing_liabilities_amount}</div>
        </td>
        <td  onClick={() => handleRowClick(item.ID)} className='highlightedYellow'>
          <div>{item.etf_interest_bearing_liability}</div>
        </td>
      </tr>

      {/* </Whisper> */}
      <tr className="hiddenRow">
        <td colSpan="31" style={{padding:0}}>
          <div className={`accordian-body collapse ${expandedRowId === item.ID ? 'show' : 'hide'}`} id={`TableRow${index}`}>
            <div className='table_accordion_row'>
            <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value' >
                                                    {item.Company_Detail.CD_Ratio_Interest}
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    {item.Company_Detail.CD_Ratio_Debt}
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Market_TotalRevenue}
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                36-mo Average Cap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Market_Trailing36MonAvrCap}
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Market_ShareOutstanding}
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Compliance_Halal}
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Compliance_NotHalal}
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.Company_Detail.CD_Compliance_Doubtful}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    {item.OwnByEtf.ETF_Revenue1.total}
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    {item.OwnByEtf.ETF_Revenue1.per_share}
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Security And Assets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.OwnByEtf.ETF_Interest_Bearing.SecAndAssets}
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.OwnByEtf.ETF_Interest_Bearing.Debt}
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Compliant
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.OwnByEtf.ETF_Revenue2.Halal}
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Non Compliant
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.OwnByEtf.ETF_Revenue2.NotHalal}
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                {item.OwnByEtf.ETF_Revenue2.Doubtful}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  ))
) : (
  <tr>
    <td colSpan="32" style={{ fontSize:20, paddingTop:30, paddingBottom:30 }}>
      No data available
    </td>
  </tr>
)}

            

                
              </tbody> 
          </table>
        </div>
        {reportCount > 0 && renderPagination()}
        </>
)}
      </div>
    </div>
    </>
  );
}

export default ETFHolding;