import React, { useState,useEffect, useCallback, useRef } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Dropdown, Placeholder, Whisper, Button, Popover, Message, useToaster, Tooltip, Input, Modal} from "rsuite";
import 'rsuite/SelectPicker/styles/index.css';
import { Link  } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
import debounce from 'lodash.debounce';
import IconDelete from "../images/deleteBG.svg";
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";

import icon_Asc from "../images/Asc.svg";
import icon_Desc from "../images/Desc.svg";

import icon1_Asc from "../images/Asc.svg";
import icon1_Desc from "../images/Desc.svg";

import icon2_Asc from "../images/Asc.svg";
import icon2_Desc from "../images/Desc.svg";

import icon3_Asc from "../images/Asc.svg";
import icon3_Desc from "../images/Desc.svg";


function ETFS() {

    const [tabName, setTabName] = useState('ETFs');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
    
const orderData = ['ASC', 'DESC'].map(
    item => ({ label: item, value: item })
);

const statusData = [
  { label: 'Publish', value: true },
  { label: 'UnPublish', value: false },
  { label: 'Both', value: '' }
];

useEffect(() => {
    AOS.init();
  }, []);

  const abortControllerRef = useRef(null);
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const apiEndPoint1 = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;
  const [pageRange, setPageRange] = useState("20");
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("US");
  const [complianceId, setComplianceId] = useState("");

  const [orderBy, setOrderBy] = useState("total_holdings"); // default orderBy
  const [order, setOrder] = useState("DESC"); // default order

  const [statusId, setStatusId] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  const [CMPTminValue, setCMPTMinValue] = useState("");
  const [CMPTmaxValue, setCMPTMaxValue] = useState("");
  
  const [NCMPTminValue, setNCMPTMinValue] = useState("");
  const [NCMPTmaxValue, setNCMPTMaxValue] = useState("");

  const [DBFTminValue, setDBFTMinValue] = useState("");
  const [DBFTmaxValue, setDBFTMaxValue] = useState("");

  const [IntrestminValue, setIntrestMinValue] = useState("");
  const [IntrestmaxValue, setIntrestMaxValue] = useState("");

  const [DEBTminValue, setDEBTMinValue] = useState("");
  const [DEBTmaxValue, setDEBTMaxValue] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [assetClassData, setAssetClassData] = useState([]);
  const [searchAsset, setSearchAsset] = useState("Equity");

  const [isAscending, setIsAscending] = useState(false);
  const [isAscending1, setIsAscending1] = useState(true);
  const [isAscending2, setIsAscending2] = useState(true);
  

  const handleIconClick = (columnName) => {
    // Toggle between ascending and descending icons
    
    setOrderBy(columnName);

    switch (columnName) {
      case "total_holdings":
        setIsAscending(!isAscending);
        setOrder(isAscending ? "DESC" : "ASC");
        break;
      case "total_uncovered":
        setIsAscending2(!isAscending2);
        setOrder(isAscending2 ? "DESC" : "ASC");
        break;
        case "total_cover":
          setIsAscending1(!isAscending1);
          setOrder(isAscending1 ? "DESC" : "ASC");
          break;
      default:
        setIsAscending(!isAscending);
        setOrder(isAscending ? "DESC" : "ASC");
        break;
    }
    
  };

    const fetchGlobalReport = async () => {
      setLoading(true);
  
      // Cancel the previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

     // Create a new AbortController for the current request
     const controller = new AbortController();
     abortControllerRef.current = controller;

    try {
        const apiUrl = `${apiEndPoint}/etf_company_profile`;
        const requestData = {
          data: {
            user_id: userid,
            limit: pageRange,
            page: currentPage.toString(),
            q:searchInput,
            asset_class:searchAsset,
            order: order,
            orderBy:orderBy,
            country: countryCode || "",
            status: complianceId,
            publish_status:statusId === 0 ? '' : statusId,
            min:minValue,
            max:maxValue,
            halalMin: CMPTminValue,
            halalMax: CMPTmaxValue,
            nonhalalMin: NCMPTminValue,
            nonhalalMax: NCMPTmaxValue,
            QueMin: DBFTminValue,
            Quemax: DBFTmaxValue,
            IntrestMin: IntrestminValue,
            IntrestMax: IntrestmaxValue,
            DebtMin: DEBTminValue,
            DebtMax: DEBTmaxValue
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData),
          signal: controller.signal
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else {
          console.error("Error: ", message);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request cancelled");
        }
        else{
  
        console.error("Error fetching data:", error.message);
        }
        return false;
      }
    };
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput,minValue,maxValue,NCMPTminValue,NCMPTmaxValue,CMPTminValue,CMPTmaxValue,DBFTminValue,DBFTmaxValue,IntrestminValue,IntrestmaxValue,DEBTminValue,DEBTmaxValue] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput,minValue,maxValue,NCMPTminValue,NCMPTmaxValue,CMPTminValue,CMPTmaxValue,DBFTminValue,DBFTmaxValue,IntrestminValue,IntrestmaxValue,DEBTminValue,DEBTmaxValue]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId,searchAsset,order,orderBy,pageRange]); // Regular effect for other dependencies

    
    const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };


    const [customPaginationValue, setCustomPaginationValue] = useState("");

    // Debounced function to update the page number
    const debouncedSetPage = useCallback(
      debounce((page) => {
        setCurrentPage(page);
      }, 500), // Delay of 500ms (half a second)
      []
    );
  
    const handlePaginationChange = (e) => {
      const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
      setCustomPaginationValue(value); // Update input value immediately for fast typing
      debouncedSetPage(Number(value)); // Trigger the debounced function
    };
  
    const handlePagelimit = (value) => {
      setCurrentPage(1);
      setCustomPaginationValue(1);
      setPageRange(value); 
    };
  
    
    const renderPagination = () => {
      // Calculate total number of pages
      const totalPages = Math.ceil(reportCount / pageRange);
    
      // Show a default of 3 pages if the total pages are more than 3
      const pageNumbers = [];
      const visiblePageCount = 4; // Number of pages to show by default
    
      let startPage = currentPage - 1;
      let endPage = currentPage + 2;
    
      // Adjust pagination if total pages are less than the default
      if (totalPages <= visiblePageCount) {
        startPage = 1;
        endPage = totalPages;
        
      } else {
        
        // Ensure the pagination does not go out of bounds
        startPage = Math.max(1, startPage);
        endPage = Math.min(totalPages, endPage);
      }
    
      // Create page numbers to display based on current page
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    
      return (
        <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
          <div className='totalRecords'>
            Total Records :  {Number(reportCount).toLocaleString()}
          </div>
          <ul className="pagination" style={{border: 0, background: 'none'}}>
  
          <li class="page-item" style={{marginLeft:10}}>
                        <SelectPicker onChange={handlePagelimit} cleanable={false}
                        value={pageRange} 
                        placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                      </li>
  
            {/* Previous Page (Double Arrow) */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
                <i className="fa fa-angle-double-left"></i>
              </a>
            </li>
    
            {/* Previous Page (Single Arrow) */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
                <i className="fa fa-angle-left"></i>
              </a>
            </li>
    
            {/* Page numbers */}
            {pageNumbers.map(page => (
              <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <a 
                  className="page-link" href="#" 
                  onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
                >
                  {page}
                </a>
              </li>
            ))}
            
            {/* Next Page (Single Arrow) */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
                <i className="fa fa-angle-right"></i>
              </a>
            </li>
    
            {/* Next Page (Double Arrow) */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
              <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
                <i className="fa fa-angle-double-right"></i>
              </a>
            </li>
            
            {/* Go to Page */}
            <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
              <input
                type="text"
                placeholder="Go#"
                className="form-control"
                value={customPaginationValue}
                onChange={handlePaginationChange}
                onInput={(e) => {
                  // Only allow numbers
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              
                style={{
                  padding: '0px 10px', 
                  color: '#878787', 
                  fontSize: 16, 
                  borderRadius: 5, 
                  border: '1px solid #A1B9ED', 
                  width: 60, 
                  height: 35, 
                  textAlign: 'center'
                }}
              />
            </li>
          </ul>
        </nav>
      );
    };
    
    
    
  
  
  // PAGINATION
  const PagingData = ['10', '20', '50', '100'].map(
    item => ({ label: item, value: item })
  );

    const handleClearFilters = () => {
      setCurrentPage(1);
      setSearchInput("");
      setSearchAsset("");
      setCountryCode("");
      setStatusId("");
      setComplianceId("");
      setOrder("");
      setMinValue("");
      setMaxValue("");
      setCMPTMinValue("");
      setCMPTMaxValue("");
      setNCMPTMinValue("");
      setNCMPTMaxValue("");
      setDBFTMinValue("");
      setDBFTMaxValue("");
      setIntrestMinValue("");
      setIntrestMaxValue("");
      setDEBTMinValue("");
      setDEBTMaxValue("");
    };

    
    const handleAssetClass = (value) => {
        setSearchAsset(value);
        setCurrentPage(1); // Reset to the first page for new search
      };


    useEffect(() => {
        // Fetch the data from API
        const fetchAssetClasses = async () => {
          try {
            const response = await fetch(`${apiEndPoint}/getAssetClassETFs`);
            const result = await response.json();
    
            if (result.data.status === 'success') {
              // Transform the data to fit SelectPicker's format
              const transformedData = result.data.data.map((item) => ({
                label: item.AssetClass,
                value: item.AssetClass,
              }));
              
              setAssetClassData(transformedData);
            }
          } catch (error) {
            console.error('Error fetching asset classes:', error);
          }
        };
    
        fetchAssetClasses();
      }, []);


      const handleCountryChange = (value) => {
        setCurrentPage(1);
        setCountryCode(value);
      };


      useEffect(() => {
        const fetchCountryList = async () => {
          const url = `${apiEndPoint}/get_country_list_etf`;
          const payload = {
            data: {
              user_id: userid // Replace with the actual user_id if dynamic
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const responseData = await response.json();
            const data = responseData.data; // Flatten the response
    
            const formattedCountryData = data.data.map((country) => ({
              label: country.country_name,
              value: country.country_code,
            }));
    
            setCountryData(formattedCountryData);
      
          } catch (error) {
            setErrorMessage(error.message);
            setMessageType('error');
            return false;
          }
        };
      
        fetchCountryList();
      }, []);


      const handleStatusChange = (value) => {
        setStatusId(value);
      };

  const handleComplianceChange = (value) => {
    setCurrentPage(1);
    setComplianceId(value);
  };

  const handleorderChange = (value) => {
    setCurrentPage(1);
    setOrder(value);
  };

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint1}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);


  

  const [openDeletePopover, setOpenDeletePopover] = useState(null);
const [publishSymbol, setPublishSymbol] = useState("");
  // Function to handle checkbox click
  const handleCheckboxClick = (item) => {
      // Toggle the popover visibility for the specific item
      setPublishSymbol(item.Symbol)
      if (openDeletePopover === item.id) {
          setOpenDeletePopover(null); // Close the popover if it's already open
      } else {
          setOpenDeletePopover(item.id); // Open the popover
      }
  };

  // // Function to handle deletion confirmation
  // const handlePublishConfirm = (Symbol) => {
      

  //     console.log(`Publish report with Symbol: ${Symbol}`);
  //     setOpenDeletePopover(null); // Close the popover after confirmation
  // };

  const handlePublishConfirm = (item) => {
  // console.log(`Publish report with Symbol: ${Symbol}`);

    // Prepare the data to be sent in the API request
    const requestData = {
        data: {
            user_id: userid, // Replace with actual user_id if needed
            symbol: item.Symbol, // Use the symbol passed to the function
            publish: item.Published ? "false" : "true"
        }
    };

    // Make the API call using fetch
    fetch(`${apiEndPoint}/publish_etf_profile`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the JSON from the response
    })
    .then((data) => {
        // Check if the status is success
        if (data.data && data.data.status === 'success') {
          console.log(data.data.message); // Log the success message
          //alert(data.data.message); // Optionally, show a success alert to the user
          setErrorMessage(data.data.message);
          setMessageType('success');
          fetchGlobalReport();
      } else {
          console.error('Unexpected response format:', data);
      }
    })
    .catch((error) => {
        console.error('Error:', error);
        // Handle the error, e.g., show an error message
    });

    setOpenDeletePopover(null); // Close the popover after confirmation
};


  const handleCheckboxChange = (e, item) => {
    setPublishSymbol(item.Symbol)
    //e.stopPropagation(); // Prevents the event from bubbling up
    // Handle the checkbox change here
    // You can check e.target.checked to see if it's checked or unchecked
};


const [open, setOpen] = useState(false);
const [CMPTopen, setCMPTOpen] = useState(false);
const [NCMPTopen, setNCMPTOpen] = useState(false);
const [DBFTopen, setDBFTOpen] = useState(false);
const [Intrestopen, setIntrestOpen] = useState(false);
const [DEBTopen, setDEBTOpen] = useState(false);

  //const toggleDropdown = () => setOpen(!open); // Toggle dropdown visibility
  const preventClose = (e) => e.stopPropagation();



  const hideMessage = () => {
    setMessageType(null);
  };

  // Error Message

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 5000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10, zIndex:999}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:999}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };
  


  // MODAL
const [EditModalOpen, setEditModalOpen] = useState(false);
const [editModalETFName, setEditModalETFName] = useState("");
const [editModalETFSymbol, setEditModalSymbol] = useState("");
const etfModelCommentRef = useRef(null);
const [etfModelComment, setEtfModelComment] = useState("");
const [isModalETFLoading, setIsModalETFLoading] = useState(false);
 const [selectedStatus, setSelectedStatus] = useState(0); 

const handleEditModalOpen = (item) => {
  setEditModalETFName(item.ETF_Name);
  setEditModalSymbol(item.Symbol);
  
  if(item.is_manual_set === "1")
  {
    setSelectedStatus(item.Compliance_status);
    // Update the textarea's value
    setEtfModelComment(item.is_manual_set_msg || "");
  }
  else{
    setSelectedStatus("");
    setEtfModelComment(""); // Clear the comment if not manual set

  }
  
  setEditModalOpen(true);
}
const handleEditModalClose = () => setEditModalOpen(false);

// const StatusData = ['Compliant', 'Non- Compliant', 'Doubtful', 'Uncovered'].map(
//     item => ({ label: item, value: item })
// );
const StatusData = ['Compliant', 'Non- Compliant', 'Doubtful', 'Uncovered'].map(item => ({ label: item, value: { 'Compliant': 'COMPLIANT', 'Non- Compliant': 'NON_COMPLIANT', 'Doubtful': 'QUESTIONABLE', 'Uncovered': 'NOT_UNDER_COVERAGE' }[item] }));

useEffect(() => {
  if (etfModelCommentRef.current) {
    etfModelCommentRef.current.value = etfModelComment;
  }
}, [etfModelComment]);



const handleManualSave = async () => {

  if (!selectedStatus) {
    setErrorMessage('Please select new compliance status');
    setMessageType('error');
    return false;
  }

  const apiUrl = `${apiEndPoint}/set_etf_status_manually`;
  const requestBody = {
    data: {
      message: etfModelComment,
      sharia_status: selectedStatus,
      etf_symbol: editModalETFSymbol,
      is_manual_set: "1",
    },
  };


  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const result = await response.json();
      console.log("API Response:", result);

      if (result.data.status === "success") {
        setEditModalOpen(false);
        setErrorMessage(result.data.message);
        setMessageType('success');
        fetchGlobalReport(); // Call fetchGlobalReport when status is success

        
      } else {
        console.error("Save failed. Message:", result.data.message);
        alert(`Save failed: ${result.data.message}`);
      }
    } else {
      console.error("Failed to save. Status:", response.status);
      alert("Save failed!");
    }
  } catch (error) {
    console.error("Error while saving:", error);
    alert("An error occurred!");
  }
};

const handleManualReset = async () => {


  setIsModalETFLoading(true);

  const apiUrl = `${apiEndPoint}/set_etf_status_manually`;
  const requestBody = {
    data: {
      message: "",
      sharia_status: "",
      etf_symbol: editModalETFSymbol,
      is_manual_set: "0",
    },
  };


  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const result = await response.json();
      console.log("API Response:", result);

      if (result.data.status === "success") {
        setIsModalETFLoading(false);
  
        setEditModalOpen(false);
        setErrorMessage(result.data.message);
        setMessageType('success');
        fetchGlobalReport(); // Call fetchGlobalReport when status is success

        
      } else {
        setIsModalETFLoading(false);
        console.error("Save failed. Message:", result.data.message);
        alert(`Save failed: ${result.data.message}`);
      }
    } else {
      setIsModalETFLoading(false);
      console.error("Failed to save. Status:", response.status);
      alert("Save failed!");
    }
  } catch (error) {
    setIsModalETFLoading(false);
    console.error("Error while saving:", error);
    alert("An error occurred!");
  }
};



const handleETFStatusChange = (selectedOption) => {
  setSelectedStatus(selectedOption); // Update selectedPeriod state with the selected option
};


  return (
    <>
    <Sidebar/>
    <div id='header'>
    {renderMessage()}
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETFS</div>
        </div>

        <div className='header_table_filter'>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search2'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search' onChange={handleSearch}   value={searchInput} />
            </div>

            <div className='dropdown_style filter_by_country'>
            <SelectPicker
                    data={assetClassData}
                    searchable={false}
                    style={{ width:180 }}
                    placeholder="All Asset classes"
                    onChange={handleAssetClass} 
                    value={searchAsset} // Bind searchAsset to the value
                    />
            </div>

            <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <div className='dropdown_style'>
                <SelectPicker
                    data={orderData}
                    searchable={false}
                    placeholder="Order By"
                    style={{ width: 80 }}
                    onChange={handleorderChange}
                    value={order}
                    />
            </div>

            {/* <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Compliance statuses"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="Both Publish Statuses"
                    />
            </div> */}

            <a href='javascript:void(0)' className="btn_clear_filter"  style={{textDecoration:"none"}} onClick={handleClearFilters}>Clear Filters</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">

      {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
    <>
        <div className='table-responsive clsPagingWrap'>
          <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Actions</th>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th>ETF Name</th>
                  {/* <th style={{textAlign:'center'}}>Market</th> */}

                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("total_holdings")}>Holdings <img   src={isAscending ? icon_Asc : icon_Desc} style={{ height: 16, marginLeft: 10 }} alt={isAscending ? "Ascending Icon" : "Descending Icon"}></img></th>
                  <th style={{cursor:'pointer'}} onClick={() => handleIconClick("total_cover")}>Covered<img   src={isAscending1 ? icon1_Asc : icon1_Desc} style={{ height: 16, marginLeft: 10 }} alt={isAscending1 ? "Ascending Icon" : "Descending Icon"}></img></th>
                  {/* <th style={{cursor:'pointer',textAlign:'center'}} onClick={() => handleIconClick("total_uncovered")}>Uncovered <img   src={isAscending2 ? icon2_Asc : icon2_Desc} style={{ height: 16, marginLeft: 10 }} alt={isAscending2 ? "Ascending Icon" : "Descending Icon"}></img></th>                   */}
                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="Uncovered"
                          open={open}
                          className='table_dropdown_button'
                          onToggle={setOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setMinValue(e)} value={minValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setMaxValue(e)} value={maxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip>Missing Asset Class</Tooltip>}> 
                  <th style={{cursor:'pointer'}}>MAC</th>
                  </Whisper>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th>Asset Class</th>
                  {/* <th style={{textAlign:'center'}}>NCMPT</th> */}
                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="NCMPT"
                          open={NCMPTopen}
                          className='table_dropdown_button'
                          onToggle={setNCMPTOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setNCMPTMinValue(e)} value={NCMPTminValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setNCMPTMaxValue(e)} value={NCMPTmaxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>
                  {/* <th>CMPT</th> */}
                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="CMPT"
                          open={CMPTopen}
                          className='table_dropdown_button'
                          onToggle={setCMPTOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setCMPTMinValue(e)} value={CMPTminValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setCMPTMaxValue(e)} value={CMPTmaxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>
                  {/* <th style={{textAlign:'center'}}>DBTF</th> */}

                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="DBFT"
                          open={DBFTopen}
                          className='table_dropdown_button'
                          onToggle={setDBFTOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setDBFTMinValue(e)} value={DBFTminValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setDBFTMaxValue(e)} value={DBFTmaxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>

                  <th style={{textAlign:'center'}}>Total</th>
                  {/* <th style={{textAlign:'center'}}>Interest</th> */}

                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="Interest"
                          open={Intrestopen}
                          className='table_dropdown_button'
                          onToggle={setIntrestOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setIntrestMinValue(e)} value={IntrestminValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setIntrestMaxValue(e)} value={IntrestmaxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>
                  {/* <th>Debt</th> */}

                  <th style={{cursor:'pointer', zIndex:999}}>
                        <Dropdown
                          title="Debt"
                          open={DEBTopen}
                          className='table_dropdown_button'
                          onToggle={setDEBTOpen} // Control dropdown visibility
                          trigger={['click']} // Dropdown opens on click
                        >
                          {/* Input inside Dropdown */}
                          <div style={{ display: 'flex', gap: 6, padding: 10 }}>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="min" onClick={preventClose} onChange={(e) => setDEBTMinValue(e)} value={DEBTminValue}/>
                            </div>
                            <div style={{ minWidth: 120 }}>
                              <Input placeholder="max" onClick={preventClose}  onChange={(e) => setDEBTMaxValue(e)} value={DEBTmaxValue}/>
                            </div>
                          </div>
                        </Dropdown>
                  </th>
                </tr>
              </thead>
              <tbody>

                
              {reportData.length > 0 ? (
  reportData.map((item, index) => (
                <tr>
                <td style={{textAlign:'center'}}>
                 
            

            <Whisper
                placement="right"
                trigger="click"
                open={openDeletePopover === item.id}
                onClose={() => setOpenDeletePopover(null)}
                speaker={
                    <Popover className="tooltip_form Rsuite_popover" preventOverflow>
                        <div className="tooltip_form_inner text-center">
                            <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                Are you sure you want to <br />
                               {item.Published ? "unpublish" : "publish"} {publishSymbol} report?
                            </div>

                            <div className='btn__group mt-5'>
                                <a href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</a>
                                <a href="#" className='btn__assign' onClick={() => handlePublishConfirm(item)}>Confirm</a>
                            </div>
                        </div>
                    </Popover>
                }
            >
                <Button className="btn_assign" onClick={() => setOpenDeletePopover(item.id)}>
                    <div>
                        <Whisper placement="right" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Publish ETF Report From Here</Tooltip>} >
                        <label className="switchSmall">
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, item)} checked={item.Published} />
                        <small></small>
                    </label>
                        </Whisper>
                    </div>
                </Button>
            </Whisper>

            <button className="btn_edit_icon" onClick={() => handleEditModalOpen(item)}>
                    <i className="icon-edit"></i>
                </button>

                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>{item.Symbol}</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>{item.ISIN}</td>
                  <td>
                  <Link to={`/ETFHolding/${item.Symbol}`} className='etf_name text_ellipsis'>{item.ETF_Name}</Link>
                  
                  </td>
                  {/* <td style={{textAlign:'center'}}>{item.Market}</td> */}
                  <td>{item.Holdings}</td>   
                  <td>{item.total_covered}</td>                
                  <td>
                  <div className='text_ellipsis'>
                    {item.Uncovered}
                  </div>    
                  </td>
                  <td>{item.empty_asset_count}</td>
                  <td style={{textAlign: 'center'}}>

                  {
  item.etf_msg ? (
    <Whisper
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      speaker={<Tooltip>{item.etf_msg}</Tooltip>}
    >
      <span
        style={{ width: 100 }}
        className={`tag_td ${
          item.Compliance === "" || item.Compliance === null
            ? ''
            : item.Compliance === "CMPT"
            ? 'halal_tag'
            : item.Compliance === "NCMPT"
            ? 'not_halal_tag'
            : item.Compliance === "DBTF"
            ? 'doughtful_tag'
            : item.Compliance === "UCVD"
            ? 'uncover_tag'
            : item.Compliance === "LEVERAGED"
            ? 'not_halal_tag'
            : item.Compliance === "DTER"
            ? 'data_error_tag'
            : ''
        }`}
      >
        {item.Compliance}
      </span>
    </Whisper>
  ) : (
    <span
      style={{ width: 100 }}
      className={`tag_td ${
        item.Compliance === "" || item.Compliance === null
          ? ''
          : item.Compliance === "CMPT"
          ? 'halal_tag'
          : item.Compliance === "NCMPT"
          ? 'not_halal_tag'
          : item.Compliance === "DBTF"
          ? 'doughtful_tag'
          : item.Compliance === "UCVD"
          ? 'uncover_tag'
          : item.Compliance === "LEVERAGED"
          ? 'not_halal_tag'
          : item.Compliance === "DTER"
          ? 'data_error_tag'
          : ''
      }`}
    >
      {item.Compliance}
    </span>
  )
}

 
</td>

                
                  <td>{item.Asset_Class}</td>
                  <td style={{textAlign:'center'}}>{item.Non_COMPLIANT_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Halal_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Doubtful_Ratio}</td>
                  <td style={{ textAlign: 'center' }}>
  {isNaN(
    parseFloat(item.Non_COMPLIANT_Ratio?.toString().replace('%', '') || 0) +
    parseFloat(item.Halal_Ratio?.toString().replace('%', '') || 0) +
    parseFloat(item.Doubtful_Ratio?.toString().replace('%', '') || 0)
  ) 
    ? '-' 
    : (
      parseFloat(item.Non_COMPLIANT_Ratio?.toString().replace('%', '') || 0) +
      parseFloat(item.Halal_Ratio?.toString().replace('%', '') || 0) +
      parseFloat(item.Doubtful_Ratio?.toString().replace('%', '') || 0)
    ).toFixed(2)
  }
</td>
                  <td style={{textAlign:'center'}}>{item.Interest_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Debt_Ratio}</td>
                </tr> 
 ))
) : (
  <tr>
    <td colSpan="14" style={{ textAlign: "center", fontSize:20, paddingTop:30, paddingBottom:30 }}>
      No data available
    </td>
  </tr>
)}
            
              </tbody> 
          </table>
      
        </div>
        {reportCount > 1 && renderPagination()}
        </>
)}      
      </div>
    </div>

    <Modal open={EditModalOpen} onClose={handleEditModalClose} className="edit_status_modal">
        <Modal.Header className="edit_modal_header">
            <Modal.Title className="edit_modal_title">{editModalETFName}</Modal.Title>
            <p>Manually Update the Compliance</p>
        </Modal.Header>

        <Modal.Body>
            <div className="edit_modal_content">
                <div className="edit_status_field">
                    <label>Select Compliance</label>
                    <div className="select_edit_status">
                        <SelectPicker
                                data={StatusData}
                                searchable={false}
                                style={{ width: '100%' }}
                                placeholder="Select the new Compliance status"
                                onChange={handleETFStatusChange} // Attach onChange event handler
                                value={selectedStatus} // Set the value prop to control the selected value
                            />
                    </div>
                </div>
               
                <div className="edit_status_field mt-4">
                    <label>Comment</label>
                    <textarea row="3" className="form-control"
                    value={etfModelComment}
                    onChange={(e) => setEtfModelComment(e.target.value)}></textarea>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal_footer_edit_status">
            <div className="btn__group">
                <button type="button" className="btn_cancel" onClick={handleEditModalClose}>Cancel</button>
                <Button className="btn___reset" onClick={handleManualReset} loading={isModalETFLoading}>Reset</Button>
                <button className="btn__save" onClick={handleManualSave}>Save</button>
            </div>
        </Modal.Footer>
        </Modal>

    </>
  );
}

export default ETFS;
